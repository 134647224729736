@use "../config/" as *;
.waf-listing-showcase {
    @extend %p-zero;
    @extend %m-y-zero;
    @extend %m-x-half-neg;
    .article {
        &-item {
            aspect-ratio: 2/3;
            @extend %relative;
            &::after {
                content: '';
                height: 65%;
                pointer-events: none;
                @extend %w-100;
                @extend %position-b-l;
                @include linear-gradient(0deg, hsla(var(--hsl-c-blue)/ 1) 70%, hsla(var(--hsl-c-blue)/ 0) 100%);
            }
        }
        &-content {
            z-index: var(--zindex1);
            @extend %p-y-two-space;
            @extend %p-x-full;
            @extend %position-b-l;
        }
        &-thumbnail {
            .img-box {
                border-radius: 0;
                img {
                    aspect-ratio: 2/3;
                    object-fit: cover;
                }
            }
        }
        &-title {
            height: auto;
            @include truncate-vertical(2, 20, 24, $font-pb);
            @extend %m-zero;
            @extend %c-pure-white-10;
        }
        &-description {
            line-height: 2rem;
            @extend %m-t-full;
            @extend %font-14-pr;
            @extend %c-pure-white-10;
        }
        &-button {
            @extend %m-t-full;
            .btn-fill {
                height: 4rem;
                @extend %w-100;
            }
            .btn-text {
                @extend %font-16-pr;
            }
        }
    }
}
.news-listing-showcase {
    &.waf-showcase  {
        .article {
            &-item {
                @extend %relative;
                &::after {
                    content: '';
                    height: 65%;
                    pointer-events: none;
                    @extend %w-100;
                    @extend %position-b-l;
                    @include linear-gradient(0deg, hsla(var(--hsl-c-blue)/ 1) 70%, hsla(var(--hsl-c-blue)/ 0) 100%);
                }
            }
            &-content {
                z-index: var(--zindex1);
                @include position(null,null,var(--two-space),0);
                @extend %p-y-two-space;
                @extend %p-x-full;
                // @extend %position-b-l;
            }
            &-title {
                font-family: $font-pb;
                text-transform: unset;
            }
        }
        .tagline {
            order: 1;
            margin-bottom: 0;
        }
        .swiper-horizontal > {
            .swiper-pagination-bullets.swiper-pagination-horizontal {
                bottom: var(--two-space);
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing-showcase {
        .layout-wrapper {
            max-width: 100%;
        }
        .article {
            &-item {
                aspect-ratio: 21/9;
                &::after {
                    width: 50%;
                    height: 100%;
                    @include linear-gradient(90deg, hsla(var(--hsl-c-blue)/ 1) 70%, hsla(var(--hsl-c-blue)/ 0) 100%);
                }
            }
            &-title {
                font-size: 2.4rem;
                line-height: 3rem;
                height: auto;
            }
            &-content {
                padding-block: 0;
                padding-right: 0;
                padding-left: var(--container-white-space);
                width: 40%;
                bottom: unset;
                left: unset;
                @include position-combo('y-center');
            }
            &-thumbnail {
                .img-box {
                    img {
                        aspect-ratio: 21/9;
                    }
                }
            }
            &-button {
                width: max-content;
            }
        }
    }
    .news-listing-showcase {
        &.waf-showcase  {
            .swiper-button {
                &-prev {
                    left: 1rem;
                }
                &-next {
                    right: 1rem;
                }
            }
            .preview-swiper {
                height: unset;
            }
            .article {
                &-item {
                    &::after {
                        content: unset;
                    }
                }
                &-wrap {
                    display: flex;
                    flex-direction: row-reverse;
                }
                &-title {
                    margin-block: 0;
                    font-size: 2.4rem;
                    line-height: 3rem;
                    height: auto;
                }
                &-description {
                    margin-block: var(--full-space) 0;
                    p {
                        @include truncate-text(3);
                    }
                }
                &-content {
                    width: unset;
                    padding: var(--header-height) 0 0 calc(var(--container-white-space) / 2);
                    position: unset;
                    background-color: var(--c-blue);
                    flex-grow: 1;
                    justify-content: center;
                    transform: unset;
                }
                &-thumbnail {
                    flex-basis: 62.7%;
                    flex-shrink: 0;
                    &::after {
                        background: linear-gradient(89.99deg, var(--c-blue) 1.61%, hsl(var(--hsl-c-blue) / 0) 36.6%);
                    }
                    .img-box {
                        padding-bottom: unset;
                        img {
                            aspect-ratio: 3/2;
                            position: unset;
                            display: block;
                            object-position: top center;
                        }
                    }
                }
                &-readmore {
                    margin-top: var(--full-space);
                    .btn-site {
                        width: auto;
                        margin-block: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-showcase.news-listing-showcase {
        .swiper-button {
            &-prev {
                left: 2.8rem;
            }
            &-next {
                right: 2.8rem;
            }
        }
        .article {
            &-content {
                padding-left: var(--container-white-space);
            }
            &-description p {
                -webkit-line-clamp: 4;
            }
        }
    }
}
@use "../config/" as *;
.waf-latest-news {
  overflow-x: hidden;
  &.waf-component {
    @extend %p-zero;
    @extend %m-x-half-neg;
  }
  .layout-wrapper {
    padding-block: 4.5rem;
    margin-inline: var(--full-space) 0;
  }
  .article {
    &-info {
      order: unset;
      @extend %m-b-half;
      p {
        @extend %font-12-pr;
      }
    }
    &-title {
      margin-block: 0 1.2rem;
      line-height: 1.3;
    }
    &-meta {
      .meta-date {
        padding-left: 0;
        margin-left: 0;
        &::before {
          content: unset;
        }
      }
    }
    &-thumbnail {
      border-radius: 1.2rem;
    }
    &-item {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        @include border-radius(0 0 1.2rem 1.2rem);
        @include linear-gradient(180deg, rgba(4, 0, 63, 0) 0%, var(--c-blue) 100%);
      }
    }
    &-content {
      z-index: var(--zindex-content);
    }
  }
  .first-list,
  .second-list {
    // width: calc(100% + var(--full-space));
    gap: var(--full-space);
    @extend %m-b-two-space;
    .article {
      &-wrap {
        @extend %relative;
      }
      &-content {
        padding: var(--one-n-half-space) var(--full-space);
        position: absolute;
        inset: 0;
        @extend %flex-column-fe-n;
      }
      &-title {
        @include truncate-vertical(2, 20);
        height: auto;
      }
      &-description {
        @extend %d-none;
      }
      &-meta {
        flex-direction: row;
      }
    }
  }
  .waf-child-wrapper,
  .article-list.second-list {
    @extend %d-none;
  }
  .third-list {
    flex-direction: column;
    gap: var(--one-n-half-space);
    height: 100%;
    overflow: visible;
    .article {
      &-item {
        @extend %w-100;
        &:after {
          content: unset;
        }
      }
      &-thumbnail {
        @extend %d-none;
      }
      &-title {
        @include truncate-vertical(3, 18);
        height: auto;
        @extend %c-blue-2-10;
      }
      &-content {
        border-left: .1rem solid var(--c-orange);
        @extend %p-x-full;
      }
      &-meta {
        .meta-date {
          @extend %c-grey-10;
        }
      }
      &-description {
        @extend %m-b-half;
        p {
          @extend %c-blue-1-10;
        }
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-latest-news {
    .layout-wrapper {
      padding-block: 8rem;
      margin-inline: auto;
    }
    .article {
      &-item {
        width: 100%;
      }
    }
    .waf-body {
      position: relative;
    }
    .first-list {
      width: 24%;
      margin-right: var(--full-space);
      margin-bottom: 4.5rem;
      flex-direction: column;
      .article {
        &-content {
          padding: var(--half-space);
          flex-wrap: nowrap;
        }
        &-title {
          font-size: 1.4rem;
          line-height: 1.2;
        }
        &-meta {
          .meta {
            font-size: 1.4rem;
          }
        }
      }
    }
    .second-list {
      width: calc(76% - var(--full-space));
      @include position-combo(tr);
      &.article-list {
        display: block;
      }
      .article {
        &-content {
          padding-inline: var(--one-n-half-space);
        }
        &-info {
          margin-bottom: auto;
        }
        &-title {
          width: 85%;
          font-size: 3.2rem;
          -webkit-line-clamp: 3;
          font-family: $font-pb;
        }
      }
    }
    .third-list {
      flex-direction: row;
      flex-wrap: wrap;
      .article {
        &-item {
          width: unset;
          flex-basis: calc(50% - 1.2rem);
        }
        &-content {
          padding-right: var(--full-space);
        }
      }
    }
  }
}
@media (min-width: $desktop-min-width) {
  .waf-latest-news {
    .first-list {
      .article {
        &-content {
          padding: var(--one-n-half-space) var(--full-space);
          flex-wrap: wrap;
        }
        &-title {
          font-size: 2rem;
        }
        &-meta {
          .meta {
            font-size: 1.4rem;
          }
        }
      }
    }
    .second-list {
      .article-title {
        font-size: 4.8rem;
      }
    }
    .third-list {
      flex-wrap: nowrap;
      .article {
        &-item {
          width: unset;
          flex: 1;
        }
      }
    }
  }
}